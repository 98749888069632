
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'
import {Posts} from 'components/Posts'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Posts mdxType="Posts" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    