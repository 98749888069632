import {css} from '@emotion/core';
import GatsbyImage from 'gatsby-image';
import {useContext} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {MIN_DEFAULT_MEDIA_QUERY, MIN_TABLET_MEDIA_QUERY} from 'typography-breakpoint-constants';
import {CurrentPageContext} from '../../contexts/CurrentPageContext';
import {colors} from '../../styles/colors';
import {linkCss} from '../../styles/css';
import {fontFamilyArrayToString, uiFontFamily} from '../../styles/fonts';
import {font100, font60, rhythm0_5} from '../../styles/typography';
import {Link} from '../Link';

const messages = defineMessages({
  empty: {id: 'components.Posts.empty', defaultMessage: 'This list is empty'},
  today: {id: 'components.Posts.today', defaultMessage: 'Today'},
  thumbnailAlt: {id: 'components.Posts.thumbnailAlt', defaultMessage: 'Thumbnail of post {title}'},
  postLink: {id: 'components.Posts.postLink', defaultMessage: '[Read more…]'},
});

const postsCss = css({
  display: ['-ms-grid', 'grid'],
  gridGap: rhythm0_5,

  gridTemplateColumns: '1fr',
  msGridColumns: '1fr',

  [MIN_TABLET_MEDIA_QUERY]: {
    gridTemplateColumns: '1fr 1fr',
    msGridColumns: '1fr 1fr',
  },

  [MIN_DEFAULT_MEDIA_QUERY]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    msGridColumns: '1fr 1fr 1fr',
  },
});

const postCss = (index: number) => {
  const index1 = index + 1;

  return css({
    background: colors.backgrounds.light,
    padding: rhythm0_5,
    borderRadius: '2px',
    msGridRow: index1,

    [MIN_TABLET_MEDIA_QUERY]: {
      msGridColumn: (index1 % 2) || 2,
      msGridRow: Math.ceil(index1 / 2),
    },

    [MIN_DEFAULT_MEDIA_QUERY]: {
      msGridColumn: (index1 % 3) || 3,
      msGridRow: Math.ceil(index1 / 3),
    },
    // boxShadow: '0 30px 60px -12px rgba(50,50,93,0.25),0 18px 36px -18px rgba(0,0,0,0.3)',
  });
};

const postTitleCss = css({
  ...font100,
  marginBottom: rhythm0_5,

  '& a': {
    color: 'inherit',
  },
});

const postLinkCss = css(linkCss, {
  ...font60,
  fontFamily: fontFamilyArrayToString(uiFontFamily),
  // color: `${colors.body.lightText} !important`,
  fontWeight: '600 !important',
  // textTransform: 'uppercase',
});

const postThumbnailCss = css({
  marginBottom: rhythm0_5,
  paddingTop: '56.25%', // 16/9 trick
  position: 'relative',
});

const postThumbnailImageCss = css({
  position: 'absolute !important',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const postContentCss = css({
  marginBottom: 0,
});

// const createdAtCss = css({
//   ...typography.adjustFontSizeTo('60%'),

//   fontFamily: fontFamilyArrayToString(uiFontFamily),
//   textTransform: 'uppercase',
//   // color: colors.body.lightText,
// });

export const Posts = () => {
  const {posts} = useContext(CurrentPageContext);

  const {formatMessage} = useIntl();

  if (!posts || posts.length === 0) {
    return (
      <div>
        <FormattedMessage {...messages.empty} />
      </div>
    );
  }

  return (
    <div css={postsCss}>
      {posts.map((post, index) => {
        // const dateDiff = moment().diff(post.createdAt, 'days');

        return (
          <article css={postCss(index)} key={`${post.path}`}>
            <header>
              {/* <div css={createdAtCss}> */}
                {/* {dateDiff === 0 && <FormattedMessage {...messages.today} />}
                {dateDiff < 8 && <FormattedRelativeTime value={-dateDiff} unit="day" />}
                {dateDiff > 8 && <FormattedDate value={post.createdAt} year="numeric" month="long" day="2-digit" />} */}

                {/* <time dateTime={format(post.createdAt, 'yyyy-MM-dd')}>
                  <FormattedDate value={post.createdAt} year="numeric" month="long" day="2-digit" />
                </time>
              </div> */}

              {post.thumbnail && (
                <div css={postThumbnailCss}>
                  <Link href={post.path}>
                    <GatsbyImage
                      css={postThumbnailImageCss}
                      fluid={post.thumbnail.posts}
                      alt={formatMessage(messages.thumbnailAlt, {title: post.title})}
                    />
                  </Link>
                </div>
              )}

              <h2 css={postTitleCss}>
                <Link css={linkCss} href={`${post.path}`}>
                  {post.title}
                </Link>
              </h2>
            </header>

            <p css={postContentCss}>
              {post.excerpt}

              <br />

              <Link css={postLinkCss} href={`${post.path}`}>
                <FormattedMessage {...messages.postLink} values={post} />
              </Link>
            </p>
          </article>
        );
      })}
    </div>
  );
};
