import {MdxBody} from '../../components/MdxBody';
import {PageLayout} from '../PageLayout';
import {CmsPageBaseLayout} from './CmsPageBaseLayout';

export const CmsPagePageLayout = (props: any) => {
  const {children, ...cmsPageBaseLayoutProps} = props;

  return (
    <CmsPageBaseLayout {...cmsPageBaseLayoutProps}>
      <PageLayout>
        <MdxBody>
          {children}
        </MdxBody>
      </PageLayout>
    </CmsPageBaseLayout>
  );
};
