import {css} from '@emotion/core';
import {useContext, useMemo} from 'react';
import {CurrentPageContext} from '../../contexts/CurrentPageContext';
import {linkCss} from '../../styles/css';
import {fontFamilyArrayToString, uiFontFamily} from '../../styles/fonts';
import {font80, rhythm0_25} from '../../styles/typography';
import {Container} from '../Container';
import {Link} from '../Link';

const breadcrumbsCss = css({
  textAlign: 'left',
  // background: colors.backgrounds.light,
  // borderTop: `1px solid ${colors.borders.common}`,
  // borderBottom: `1px solid ${colors.borders.common}`,

  '@media print': {
    display: 'none',
  },

  '& ul': {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },

  '& li': {
    ...font80,

    lineHeight: 1.1,
    fontFamily: fontFamilyArrayToString(uiFontFamily),
    fontWeight: 400,
    display: 'inline-block',
    // textTransform: 'uppercase',
    marginRight: rhythm0_25,

    '& + li::before': {
      display: 'inline-block',
      // content: '"🢒"',
      content: `${JSON.stringify('›')}`,
      marginRight: rhythm0_25,
    },
  },

  '& a': [linkCss, {
    fontWeight: 600,
    display: 'inline-block',
    // color: 'inherit',
  }],
});

export const Breadcrumbs = () => {
  const {title, breadcrumbs} = useContext(CurrentPageContext);

  const last = useMemo(
    () => breadcrumbs?.find((it) => !it.path),
    [],
  );

  const parents = useMemo(
    () => (breadcrumbs || []).filter((it) => !!it.path),
    [],
  );

  return (
    <nav css={breadcrumbsCss}>
      <Container>
        <ul>
          {parents.map((breadcrumb) => (
            <li key={breadcrumb.path!}>
              <Link href={breadcrumb.path!}>
                {breadcrumb.label}
              </Link>
            </li>
          ))}

          <li>{last?.label || title}</li>
        </ul>
      </Container>
    </nav>
  );
};
