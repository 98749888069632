import {css} from '@emotion/core';
import {useLocation} from '@reach/router';
import {useContext} from 'react';
import {Breadcrumbs} from '../components/Breadcrumbs';
import {Container} from '../components/Container';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {MainContent} from '../components/MainContent';
import {CurrentPageContext} from '../contexts/CurrentPageContext';
import {rhythm1, rhythm2, rhythm3} from '../styles/typography';
import {BaseLayout} from './BaseLayout';

const mainCss = css({
  // background: 'white',

  minHeight: [`calc(100vh - ${rhythm3})`, `calc(var(--window-height, 100vh) - ${rhythm3})`],

  padding: `${rhythm1} 0 ${rhythm2}`,

  '@media print': {
    paddingBottom: 0,
    minHeight: 0,
  },
});

const bodyCss = css({
  '& > p:last-child': {
    marginBottom: 0,
  },
});

type Props = {
  children: React.ReactNode,
};

export const PageLayout = (props: Props) => {
  const {children} = props;

  const {pathname} = useLocation();

  const {title} = useContext(CurrentPageContext);

  return (
    <BaseLayout>
      <Header />

      <Breadcrumbs />

      <div css={mainCss}>
        <MainContent key={pathname}>
          <main css={bodyCss}>
            <Container>
              <h1>{title}</h1>

              {children}
            </Container>
          </main>
        </MainContent>
      </div>

      <Footer />
    </BaseLayout>
  );
};

PageLayout.whyDidYouRender = true;
